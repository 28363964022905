var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal_wrap",
      class: { is_show: _vm.show },
      on: { click: _vm.dimEvt },
    },
    [
      _vm.alertData
        ? _c("div", { staticClass: "modal s type_dialog" }, [
            _vm.alertData.title
              ? _c("div", { staticClass: "modal_head" }, [
                  _c("h3", { staticClass: "ttl" }, [
                    _vm._v(_vm._s(_vm.alertData.title)),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "modal_body" },
              [
                _vm.alertData.type === "warn" ||
                _vm.alertData.type === "warn_2" ||
                _vm.alertData.type === "warn_3"
                  ? _c("IcWarn")
                  : _vm.alertData.type === "success"
                  ? _c("IcCheckMark")
                  : _vm.alertData.type === "question" ||
                    _vm.alertData.type === "question2"
                  ? _c("IcQuestionMark")
                  : _vm.alertData.type === "networkError"
                  ? _c("div", { staticClass: "img_area" }, [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(max-width: 600px)",
                            type: "image/png",
                            srcset: `${require("@/assets/img/img_mob_illust_network_l.png")} 4x, ${require("@/assets/img/img_mob_illust_network_m.png")} 3x, ${require("@/assets/img/img_mob_illust_network.png")}`,
                          },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/img_illust_network.png"),
                            alt: "img_illust_network",
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c("h3", { staticClass: "ttl-bigger" }, [
                  _vm._v(_vm._s(_vm.alertData.titleDescription)),
                ]),
                _c("p", { staticClass: "txt" }, [
                  _vm._v(_vm._s(_vm.alertData.desc)),
                ]),
                _vm.alertData.type === "networkError"
                  ? _c("p", { staticClass: "txt-left" }, [
                      _vm._v(
                        " Sorry, this service is currently unavailable in your country. "
                      ),
                    ])
                  : _vm._e(),
                _vm.alertData.type === "networkError"
                  ? _c("p", { staticClass: "txt-left" }, [
                      _vm._v(
                        " We deeply appreciate your interest. We are working our best to expand our service to your country. "
                      ),
                    ])
                  : _vm._e(),
                _vm.alertData.type === "networkError"
                  ? _c("p", { staticClass: "txt-left" }, [
                      _vm._v(" Please stay tuned! "),
                    ])
                  : _vm._e(),
                _vm.alertData.additional
                  ? _c(
                      "div",
                      { staticClass: "wrap-addition-alert" },
                      _vm._l(_vm.alertData.additional, function (itm, key) {
                        return _c(
                          "div",
                          { key: key, staticClass: "addition-itm" },
                          [
                            _c("div", { staticClass: "additional-lb" }, [
                              _vm._v(_vm._s(itm.label)),
                            ]),
                            _c("div", { staticClass: "additional-vl" }, [
                              _vm._v(_vm._s(itm.value)),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "modal_foot" }, [
              _vm.alertData.type === "question" ||
              _vm.alertData.type === "question2" ||
              _vm.alertData.type === "warn_2" ||
              _vm.alertData.type === "warn_3"
                ? _c("div", { staticClass: "btn_area flex" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn h_m basic",
                        attrs: { type: "button" },
                        on: { click: _vm.close },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _vm.alertData.type === "question"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn h_m strong",
                            attrs: { type: "button" },
                            on: { click: _vm.submit },
                          },
                          [_vm._v(" OK ")]
                        )
                      : _vm._e(),
                    _vm.alertData.type === "question2"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn h_m strong",
                            attrs: { type: "button" },
                            on: { click: _vm.close2 },
                          },
                          [_vm._v(" OK ")]
                        )
                      : _vm._e(),
                    _vm.alertData.type === "warn_2"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn h_m strong",
                            attrs: { type: "button", title: _vm.popupTitle },
                            on: { click: _vm.submit },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.alertData.success
                                    ? _vm.alertData.success
                                    : "OK"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.alertData.type === "warn_3"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn h_m strong",
                            attrs: { type: "button" },
                            on: { click: _vm.goMySettings },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.alertData.success
                                    ? _vm.alertData.success
                                    : "OK"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _c("div", { staticClass: "btn_area" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn w_m h_m strong",
                        attrs: { type: "button" },
                        on: { click: _vm.success },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.alertData.success
                                ? _vm.alertData.success
                                : "OK"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
            ]),
            _c(
              "button",
              {
                staticClass: "btn type_ic close_modal",
                on: { click: _vm.close },
              },
              [_c("IcCloseM")],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }