var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "edit_item type_card" }, [
    _c("div", { staticClass: "item_head" }, [
      _c(
        "button",
        {
          staticClass: "thumb_area thumb_btn",
          attrs: { "aria-hidden": "true" },
          on: {
            click: function ($event) {
              return _vm.goArtworkDetail(
                _vm.itemData.artworkId,
                _vm.itemData.editionId
              )
            },
            focus: function ($event) {
              _vm.profileFocused = true
            },
            blur: function ($event) {
              _vm.profileFocused = false
            },
          },
        },
        [
          _c("div", [
            _vm.source.small
              ? _c("img", {
                  attrs: {
                    src: _vm.source.small,
                    alt: _vm.itemData.title ? _vm.itemData.title : "",
                    width: 480,
                  },
                })
              : _c("img", {
                  attrs: {
                    src: require("@/assets/img/img_illust_nodata.png"),
                    alt: "nodata",
                  },
                }),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "ic_area type_blockchains" },
        [_vm.itemData.bcNwTp == "10" ? _c("IcHedera") : _c("IcEth")],
        1
      ),
      _c(
        "button",
        {
          staticClass: "btn type_ic btn_like",
          class: {
            is_active: _vm.likeStatus,
          },
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.likeThis(_vm.itemData, _vm.itemIndex)
            },
          },
        },
        [
          !_vm.likeStatus
            ? _c("IcLike", {
                ref: "icLike",
                attrs: { "icon-color": "#fff", status: !_vm.likeStatus },
              })
            : _c("IcLike", {
                ref: "icLike",
                attrs: { "icon-color": "#ff521d" },
              }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "item_body", class: { activate: _vm.profileFocused } },
      [
        _c("div", {
          staticClass: "link",
          attrs: {
            "aria-describedby": _vm.itemData.artworkId,
            title: _vm.itemData.artworkId,
          },
          on: {
            click: function ($event) {
              return _vm.goArtworkDetail(
                _vm.itemData.artworkId,
                _vm.itemData.editionId
              )
            },
          },
        }),
        _c("div", { staticClass: "basic_info_group" }, [
          _c(
            "button",
            {
              staticClass: "profile_info is_art",
              on: {
                click: function ($event) {
                  return _vm.goArtistProfile()
                },
                focus: function ($event) {
                  _vm.profileFocused = true
                },
                blur: function ($event) {
                  _vm.profileFocused = false
                },
              },
            },
            [
              _vm.itemData.artistInfo.avatarInfo &&
              _vm.itemData.artistInfo.avatarInfo.length
                ? _c("span", {
                    staticClass: "profile",
                    style: `background-image: url(${_vm.itemData.artistInfo.avatarInfo[0].resUrl})`,
                  })
                : _c("IcProfile", { staticClass: "profile" }),
              _c("IcRing", {
                staticClass: "ic_ring",
                attrs: { width: 32, height: 32 },
              }),
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.itemData.artistInfo.name)),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "ttl_area" }, [
            _c(
              "h3",
              { staticClass: "ttl", attrs: { id: _vm.itemData.artworkId } },
              [_vm._v(_vm._s(_vm.itemData.title))]
            ),
            _c("p", { staticClass: "edit" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.itemData.totalEdition > 1
                      ? `${_vm.itemData.totalEdition} Editions`
                      : `${_vm.itemData.totalEdition} Edition`
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "state_info clearFix" }, [
          _c(
            "div",
            { staticClass: "price_area" },
            [
              _vm.price === null
                ? [
                    !_vm.price
                      ? _c("p", { staticClass: "price chip" }, [
                          _vm._v("Loading..."),
                        ])
                      : _vm._e(),
                  ]
                : [
                    [
                      !_vm.price || _vm.price === 0
                        ? _c("p", { staticClass: "price chip" }, [
                            _vm._v(" Not for Sale "),
                          ])
                        : _vm._e(),
                    ],
                    _vm.price && _vm.price !== 0
                      ? [
                          _c("p", { staticClass: "type" }, [
                            _vm._v("Floor Price"),
                          ]),
                          _c("p", { staticClass: "price" }, [
                            _vm.itemData.bcNwTp == "10"
                              ? _c("b", [
                                  _vm._v(
                                    _vm._s(_vm._f("setNum")(_vm.price)) +
                                      " USDC"
                                  ),
                                ])
                              : _c("b", [
                                  _vm._v(_vm._s(_vm.price)),
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v("Ξ"),
                                  ]),
                                  _c("span", { staticClass: "dollars" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm._f("crtpyAbbreviate")(
                                            _vm.priceToEthUsd
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                          ]),
                        ]
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }