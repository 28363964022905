var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal_wrap",
      class: { is_show: _vm.show },
      attrs: { id: "mail_modal" },
    },
    [
      _c("div", { staticClass: "modal helpCenter" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal_body" }, [
          _c("div", { staticClass: "introduce_wrap" }, [
            _c("div", { staticClass: "input_area type_hoz text" }, [
              _c(
                "div",
                {
                  staticClass: "input_box",
                  class: _vm.reg.test(this.email) ? "" : "is_error",
                },
                [
                  _c("label", { attrs: { for: "txt-email" } }, [
                    _vm._v("* Your email address"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    attrs: {
                      id: "txt-email",
                      type: "text",
                      "aria-describedby": "text-email-error",
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                        function ($event) {
                          return _vm.isValidText()
                        },
                      ],
                    },
                  }),
                ]
              ),
              _vm.errorMsg
                ? _c("p", { staticClass: "validation" }, [
                    _vm.isError
                      ? _c("span", { attrs: { id: "text-email-error" } }, [
                          _vm._v("This email is not valid. ex)abcd@kkk.com"),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "input_area type_hoz text" }, [
              _c("label", { attrs: { for: "subject" } }, [_vm._v("* Subject")]),
              _c("div", { staticClass: "input_box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.subject,
                      expression: "subject",
                    },
                  ],
                  attrs: { type: "text", label: "Subject", id: "subject" },
                  domProps: { value: _vm.subject },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.subject = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "input_area type_hoz textarea" }, [
              _c("label", { attrs: { for: "description" } }, [
                _vm._v("* Description"),
              ]),
              _c("div", { staticClass: "input_box" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description",
                    },
                  ],
                  attrs: {
                    id: "description",
                    "aria-labelledby": "description",
                    title: "Description",
                  },
                  domProps: { value: _vm.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.description = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal_foot" }, [
          _c("div", { staticClass: "btn_area" }, [
            _c(
              "button",
              { staticClass: "btn basic w_xs h_m", on: { click: _vm.close } },
              [_vm._v("Cancel")]
            ),
            _c(
              "button",
              {
                staticClass: "btn strong w_xs h_m",
                on: { click: _vm.sendEmail },
              },
              [_vm._v("Send")]
            ),
          ]),
        ]),
        _c(
          "button",
          { staticClass: "btn type_ic close_modal", on: { click: _vm.close } },
          [_c("IcCloseM")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal_head" }, [
      _c("h3", { staticClass: "modal_ttl" }, [_vm._v("Contact Artist")]),
      _c("span", [
        _vm._v(" Send email to offer your purchase price to the artist "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }