// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ttl-bigger[data-v-0b84a9d6] {
  margin-top: 10px;
  font-size: 1.6rem;
}
.wrap-addition-alert[data-v-0b84a9d6] {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 7px;
}
.addition-itm[data-v-0b84a9d6] {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.additional-vl[data-v-0b84a9d6] {
  font-weight: bold;
}
.txt-left[data-v-0b84a9d6] {
  margin-top: 1.2rem;
  font-size: 1.3rem !important;
  font-weight: 300;
  line-height: 1.375;
  color: #fff;
  white-space: pre-line;
  text-align: left;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
